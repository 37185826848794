import * as React from "react"

const LogoJava = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={150} height={60} {...props}>
        <path
            d="M21.43 42.108s-1.843 1.122 1.282 1.443c3.767.48 5.77.4 9.938-.4a15.03 15.03 0 0 0 2.645 1.282c-9.377 4.007-21.24-.24-13.866-2.324zm-1.202-5.21s-2.004 1.523 1.122 1.843c4.088.4 7.293.48 12.824-.64a4.89 4.89 0 0 0 1.923 1.202c-11.3 3.366-23.964.32-15.87-2.404zm22.12 9.137s1.363 1.122-1.523 2.004c-5.37 1.603-22.522 2.084-27.33 0-1.683-.72 1.523-1.763 2.565-1.923 1.042-.24 1.603-.24 1.603-.24-1.843-1.282-12.263 2.645-5.3 3.767 19.155 3.126 34.944-1.362 29.975-3.607zM22.312 31.448s-8.736 2.084-3.126 2.805c2.404.32 7.133.24 11.54-.08 3.607-.32 7.213-.962 7.213-.962s-1.282.56-2.164 1.122c-8.896 2.324-25.968 1.282-21.08-1.122 4.168-2.004 7.614-1.763 7.614-1.763zm15.63 8.736c8.976-4.65 4.81-9.137 1.924-8.576-.72.16-1.042.32-1.042.32s.24-.48.8-.64c5.69-2.004 10.18 6.01-1.843 9.137 0 0 .08-.08.16-.24zM23.194 52.847c8.656.56 21.88-.32 22.2-4.408 0 0-.64 1.603-7.133 2.805-7.374 1.363-16.5 1.202-21.88.32 0 0 1.122.962 6.812 1.282z"
            fill="#4e7896"
        />
        <path
            d="M32.49 5s4.97 5.05-4.73 12.663c-7.774 6.17-1.763 9.698 0 13.705-4.568-4.087-7.854-7.694-5.6-11.06C25.438 15.34 34.494 12.935 32.49 5zm-2.565 23.002c2.324 2.645-.64 5.05-.64 5.05s5.93-3.046 3.206-6.813c-2.485-3.607-4.408-5.37 6.01-11.38 0 0-16.43 4.088-8.576 13.144z"
            fill="#f58219"
        />
        <path
            d="M91.677 44.583V26.397c0-4.64-2.598-7.794-9.093-7.794-3.71 0-6.866.928-9.65 2.04l.928 3.34c2.04-.742 4.64-1.485 7.423-1.485 3.526 0 5.196 1.485 5.196 4.454v2.412h-1.856c-8.907 0-13 3.34-13 8.722 0 4.454 2.784 7.052 7.794 7.052 3.155 0 5.567-1.485 7.794-3.34l.37 2.784h4.083zm-5.196-5.938c-1.856 1.67-3.897 2.598-5.753 2.598-2.412 0-3.897-1.485-3.897-4.083s1.485-4.454 7.423-4.454h2.227v5.938zm22.64 5.938h-6.495L94.832 19.16h5.567l4.825 15.588 1.113 4.64c2.413-6.68 4.268-13.547 5.01-20.227h5.567c-1.485 8.35-4.082 17.443-7.794 25.423zm30.062 0V26.397c0-4.64-2.598-7.794-9.093-7.794-3.71 0-6.866.928-9.65 2.04l.742 3.34c2.227-.742 4.825-1.485 7.608-1.485 3.526 0 5.196 1.485 5.196 4.454v2.412h-1.856c-8.907 0-13 3.34-13 8.722 0 4.454 2.598 7.052 7.608 7.052 3.34 0 5.753-1.485 7.98-3.34l.37 2.784h4.083zm-5.196-5.938c-1.856 1.67-3.897 2.598-5.753 2.598-2.412 0-3.897-1.485-3.897-4.083s1.484-4.454 7.423-4.454h2.227v5.938zM65.883 48.85c-1.485 2.227-3.71 3.897-6.495 4.825l-2.413-2.97c1.856-1.113 3.712-2.783 4.454-4.268.742-1.3.928-3.155.928-7.423v-28.95h5.567v28.578c0 5.753-.557 7.98-2.04 10.206z"
            fill="#4e7896"
        />
    </svg>
)

export default LogoJava
