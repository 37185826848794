import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import LocalizedTag from '../../i18n/LocalizedTag';

const AboutUs = () => (
  <ScrollableAnchor id="o-nas">
    <section className="Section Section--aboutUs">
      <div className="Section-main">
        <LocalizedTag id="about" type="h2" className="Section-mainTitle" />
        <LocalizedTag id="aboutCatchword" type="h3" className="Section-title" />
        <LocalizedTag id="aboutText" type="p" className="Section-text" />
      </div>
      <div className="Section-aside">
        <ul className="List List--themeLight">
          <li className="List-item">
            <span className="List-icon">
              <svg width="20px" height="20px" viewBox="0 0 20 20">
                <path
                  fill="#f92644"
                  d="M17.8,1.2c-1.213,0-2.812,1.035-4.409,2.474C12.431,1.561,11.265,0,10.008,0s-2.4,1.53-3.357,3.612C5.414,2.506,3.062.641,1.567,1.358-1.4,2.782.51,13.585,1.924,16.577A5.881,5.881,0,0,0,7.064,20h4.854v-.008a6.156,6.156,0,0,0,5.905-3.415C19.317,13.416,21.878,1.2,17.8,1.2M4.559,16.385C3.006,14.7,1.623,6.135,2.307,3.664a.486.486,0,0,1,.661-.312A12.246,12.246,0,0,1,5.882,5.6C4.9,8.35,3.366,13.612,4.559,16.385m8.214.477a3.943,3.943,0,0,1-5.359.143,3.988,3.988,0,0,1-1.378-3c0-2.985,2.056-9.856,3.61-11.634a.483.483,0,0,1,.722,0C11.92,4.132,13.981,10.957,13.981,14a3.994,3.994,0,0,1-1.208,2.862m2.36.188A5.989,5.989,0,0,0,15.967,14a30.792,30.792,0,0,0-1.776-8.33,12.343,12.343,0,0,1,3.016-2.32.485.485,0,0,1,.659.311c.648,2.3-.452,11.452-2.733,13.389"
                  transform="translate(0 0)"
                />
              </svg>
            </span>
            <LocalizedTag type="h4" className="List-heading" id="aboutSoftwareDevelopment" />
            <LocalizedTag type="p" className="List-text" id="aboutSoftwareDevelopmentText" />
          </li>
          <li className="List-item">
            <span className="List-icon">
              <svg width="20px" height="20px" viewBox="0 0 20 20">
                <path
                  fill="#f92644"
                  d="M17.8,1.2c-1.213,0-2.812,1.035-4.409,2.474C12.431,1.561,11.265,0,10.008,0s-2.4,1.53-3.357,3.612C5.414,2.506,3.062.641,1.567,1.358-1.4,2.782.51,13.585,1.924,16.577A5.881,5.881,0,0,0,7.064,20h4.854v-.008a6.156,6.156,0,0,0,5.905-3.415C19.317,13.416,21.878,1.2,17.8,1.2M4.559,16.385C3.006,14.7,1.623,6.135,2.307,3.664a.486.486,0,0,1,.661-.312A12.246,12.246,0,0,1,5.882,5.6C4.9,8.35,3.366,13.612,4.559,16.385m8.214.477a3.943,3.943,0,0,1-5.359.143,3.988,3.988,0,0,1-1.378-3c0-2.985,2.056-9.856,3.61-11.634a.483.483,0,0,1,.722,0C11.92,4.132,13.981,10.957,13.981,14a3.994,3.994,0,0,1-1.208,2.862m2.36.188A5.989,5.989,0,0,0,15.967,14a30.792,30.792,0,0,0-1.776-8.33,12.343,12.343,0,0,1,3.016-2.32.485.485,0,0,1,.659.311c.648,2.3-.452,11.452-2.733,13.389"
                  transform="translate(0 0)"
                />
              </svg>
            </span>
            <LocalizedTag type="h4" className="List-heading" id="aboutBodyshopping" />
            <LocalizedTag type="p" className="List-text" id="aboutBodyshoppingText" />
          </li>
          <li className="List-item">
            <span className="List-icon">
              <svg width="20px" height="20px" viewBox="0 0 20 20">
                <path
                  fill="#f92644"
                  d="M17.8,1.2c-1.213,0-2.812,1.035-4.409,2.474C12.431,1.561,11.265,0,10.008,0s-2.4,1.53-3.357,3.612C5.414,2.506,3.062.641,1.567,1.358-1.4,2.782.51,13.585,1.924,16.577A5.881,5.881,0,0,0,7.064,20h4.854v-.008a6.156,6.156,0,0,0,5.905-3.415C19.317,13.416,21.878,1.2,17.8,1.2M4.559,16.385C3.006,14.7,1.623,6.135,2.307,3.664a.486.486,0,0,1,.661-.312A12.246,12.246,0,0,1,5.882,5.6C4.9,8.35,3.366,13.612,4.559,16.385m8.214.477a3.943,3.943,0,0,1-5.359.143,3.988,3.988,0,0,1-1.378-3c0-2.985,2.056-9.856,3.61-11.634a.483.483,0,0,1,.722,0C11.92,4.132,13.981,10.957,13.981,14a3.994,3.994,0,0,1-1.208,2.862m2.36.188A5.989,5.989,0,0,0,15.967,14a30.792,30.792,0,0,0-1.776-8.33,12.343,12.343,0,0,1,3.016-2.32.485.485,0,0,1,.659.311c.648,2.3-.452,11.452-2.733,13.389"
                  transform="translate(0 0)"
                />
              </svg>
            </span>
            <LocalizedTag type="h4" className="List-heading" id="aboutWorkshops" />
            <LocalizedTag type="p" className="List-text" id="aboutWorkshopsText" />
          </li>
        </ul>
      </div>
    </section>
  </ScrollableAnchor>
);

export default AboutUs;
