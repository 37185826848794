import React from 'react';

import SEO from '../components/seo';

import '../../assets/styles/Web.scss';
import SiteHeader from '../components/SiteHeader';
import Footer from '../components/sections/Footer';
import AboutUs from '../components/sections/AboutUs';
import withIntl from '../i18n/withIntl';
import References from '../components/sections/References';
import RecentWorkshops from '../components/RecentWorkshops';
import AsideSocialMenu from '../components/AsideSocialMenu';

// load fetch only for browser
if (typeof window !== 'undefined') {
  import('whatwg-fetch').then(() => {});
}

const Index = () => (
  <body>
    <SEO />
    <SiteHeader />
    <AsideSocialMenu />
    <main>
      <article>
        <AboutUs />
        <RecentWorkshops />
        <References />
      </article>
    </main>
    <Footer />
  </body>
);

export default withIntl(Index);
