import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import ContactForm from '../ContactForm';
import FooterLogo from '../FooterLogo';
import Contact from '../Contact';
import LocalizedTag from '../../i18n/LocalizedTag';

const Footer = () => (
  <footer className="Footer">
    <ScrollableAnchor id="kontakt">
      <section>
        <Contact />
      </section>
    </ScrollableAnchor>
    <ScrollableAnchor id="napiste-nam">
      <section className="Section Section--contact Section--themeLight">
        <div className="Section-main">
          <LocalizedTag className="Section-mainTitle" type="h2" id="contactUs" />
          <LocalizedTag className="Section-text" type="p" id="writeUsText" />
          <ContactForm />
        </div>
      </section>
    </ScrollableAnchor>
    <FooterLogo />
  </footer>
);
export default Footer;
