import React from 'react';
import LogoJava from "./LogoJava";
import LogoNode from "./LogoNode";
import LogoReact from "./LogoReact"
import LocalizedTag from '../i18n/LocalizedTag';
import HideableHeader from './HideableHeader';
import LogoFlutter from "./LogoFlutter";

const moveTo = (anchor) => () => {
  window.location.hash = anchor;
};

const SiteHeader = () => (
  <header className="Cover">
    <HideableHeader landingPage />
    <div className="Cover-main">

      <h1 className="Cover-mainTitle">
        <b>
          <LocalizedTag id="catchwordBold" type="span" />
        </b>
        <br />
      </h1>
      <br />
      <h1 className="Cover-mainTitle">
        <LocalizedTag id="catchword" type="span" />
      </h1>
      <br />
      <LogoReact />
      <LogoFlutter />
      <LogoNode />
      <LogoJava />
    </div>
    <div className="Cover-footer">
      <button type="button" className="Cover-continue" onClick={moveTo('o-nas')}>
        <svg viewBox="0 0 96 148.321" width="26px" height="41px">
          <g>
            <path
              fill="#FFFFFF"
              d="M48,148.321a48.055,48.055,0,0,1-48-48V48a48,48,0,0,1,96,0v52.321A48.055,48.055,0,0,1,48,148.321ZM48,8A40.045,40.045,0,0,0,8,48v52.321a40,40,0,0,0,80,0V48A40.045,40.045,0,0,0,48,8Z"
            />
            <path
              fill="#FFFFFF"
              d="M48,58.821a4,4,0,0,1-4-4V36.071a4,4,0,0,1,8,0v18.75A4,4,0,0,1,48,58.821Z"
            />
          </g>
        </svg>
      </button>
    </div>
  </header>
);
export default SiteHeader;
