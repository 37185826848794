import React, { useState } from 'react';
import { Formik } from 'formik';
import Modal from './Modal';
import SubmitButton from './SubmitButton';
import FormikTextField from './FormikTextField';
import { postData } from '../utils/fetch';
import { contactFormScheme } from '../utils/validations';
import LocalizedTag from '../i18n/LocalizedTag';

const ContactForm = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const modalText = <LocalizedTag type="p" id="modalMessageWasSent" />;
  return (
    <>
      {modalOpen && <Modal closeModal={() => setModalOpen(false)} text={modalText} />}
      <Formik
        initialValues={{
          name: '',
          email: '',
          message: '',
        }}
        validationSchema={contactFormScheme}
        onSubmit={(values, formikActions) => {
          postData(`${process.env.FIREBASE_API_URL}/public/contact`, values).then(() => {
            formikActions.setSubmitting(false);
            formikActions.resetForm();
            setModalOpen(true);
          });
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit} className="ContactForm">
            <FormikTextField name="name" floatingLabel="formName" />
            <FormikTextField name="email" floatingLabel="formEmail" />
            <FormikTextField
              name="message"
              floatingLabel="formMessage"
              multiline
              className="ContactForm-field Textarea"
            />
            <FormikTextField
              name="country"
              floatingLabel="formCountry"
              style={{ visibility: 'hidden', position: 'absolute', width: 'auto' }}
            />
            <SubmitButton isSubmitting={isSubmitting} />
          </form>
        )}
      </Formik>
    </>
  );
};

export default ContactForm;
