import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import foxconnlogo from '../../../assets/images/foxconn-logo.png';
import LocalizedTag from '../../i18n/LocalizedTag';
import mariuspedersenlogo from '../../../assets/images/mariuspedersen-logo.png';
import dixonscarphonelogo from '../../../assets/images/dixonscarphone-logo.png';
import zvuservislogo from '../../../assets/images/zvuservis-logo.png';
import preonlogo from '../../../assets/images/preon-logo.png';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const renderTestimonial = (reference) => (
  <div className="Testimonial">
    <LocalizedTag
      id={`referenceDescription${reference}`}
      type="p"
      className="Testimonial-description"
    />
    <p className="Testimonial-writter">
      <LocalizedTag id={`referenceClient${reference}`} type="span" className="Testimonial-client" />
      ,
      <LocalizedTag id={`referencePosition${reference}`} type="span" />
      <LocalizedTag
        id={`referenceCompany${reference}`}
        type="span"
        className="Testimonial-company"
      />
    </p>
  </div>
);

const References = () => (
  <ScrollableAnchor id="reference">
    <section className="Section Section--projects Section--themeLight">
      <div className="Section-main">
        <div className="CompanyList">
          <LocalizedTag id="trust" type="h2" className="CompanyList-heading" />
          <Carousel responsive={responsive}>
            <img src={foxconnlogo} alt="Foxconn logo" height="70" width="150" />
            <img src={dixonscarphonelogo} alt="Dixons carphone logo" height="70" width="150" />
            <img src={mariuspedersenlogo} alt="Marius pedersen logo" height="70" width="150" />
            <img src={zvuservislogo} alt="ZVU Servis logo" height="70" width="150" />
            <img src={preonlogo} alt="Preon logo" height="70" width="150" />
          </Carousel>
        </div>
        {renderTestimonial(Math.floor(Math.random() * 2))}
      </div>
    </section>
  </ScrollableAnchor>
);

export default References;
