import React, { useContext } from 'react';
import facebookIcon from '../../assets/images/facebook-logo.png';
import linkedinIcon from '../../assets/images/linkedin-logo.png';
import LanguageContext from '../i18n/LanguageContext';

const socialMenu = (
  <aside>
    <nav className="SocialMenu">
      <ul className="List">
        <li className="List-item">
          <a className="Link Link--passive" href="https://www.linkedin.com/company/sqnsoftware/">
            <img className="Icon Icon--linkedin" src={linkedinIcon} alt="linkedin_logo" />
          </a>
        </li>
        <li className="List-item">
          <a className="Link Link--passive" href="https://www.facebook.com/SQNsoftware/">
            <img className="Icon Icon--facebook" src={facebookIcon} alt="facebook_logo" />
          </a>
        </li>
      </ul>
    </nav>
  </aside>
);

const AsideSocialMenu = () => {
  const currentLanguage = useContext(LanguageContext);
  return currentLanguage.locale === 'cs' ? socialMenu : '';
};

export default AsideSocialMenu;
