import React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import Button from './Button';

const TrainingCard = ({ allTrainings, topic, isDetail = false, scheduleOnly = false }) => {
  const gatsbyImage = allTrainings?.filter((ws) => ws.name === topic.topicName)[0]?.img
    ?.childImageSharp?.gatsbyImageData;

  return (
    <div className={scheduleOnly ? 'Workshops-workshopBoxReduced' : 'Workshops-workshopBox'}>
      {!scheduleOnly && (
        <div className="Workshops-leftsideBox">
          <div className="Workshops-imageWrapper">
            {gatsbyImage ? (
              <GatsbyImage image={gatsbyImage} alt="logo of workshop topic" />
            ) : (
              <img
                src={topic.topicLogo}
                alt="logo of workshop topic"
                className="Workshops-imageLeft"
              />
            )}
          </div>
          <div className="Workshops-rightsideBox">
            <h3 className="Workshops-topicName">{topic.topicName}</h3>
            <p className="Workshops-descriptionJustified TopicDetail-lineBreak">
              {topic.topicDescription}
            </p>
            {!isDetail && (
              <div className="Workshops-buttonWrapper">
                <Link to="/#napiste-nam">
                  <Button inverted label="moreInfoTrainings" />
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TrainingCard;
