import * as React from "react"

const LogoFlutter = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width={120}
        height={60}
        {...props}
    >
        <g transform="matrix(.03882 0 0 .03882 -.56 8.162)">
            <defs>
                <path
                    id="a"
                    d="M959.4 500 679.8 779.7l279.6 279.7H639.9L360.2 779.7 639.9 500h319.5zM639.9 20.7 120.6 540l159.8 159.8 679-679.1H639.9z"
                />
                <path
                    id="m"
                    d="M79.564 38.728q-.812 0-1.518-.262-.68-.262-1.152-.707-.524-.497-.785-1.152-.262-.654-.262-1.597v-7.827h-2.33v-2.016h2.33v-3.77h2.225v3.77h3.246v2.016h-3.246v7.278q0 1.1.42 1.623.497.576 1.44.576.76 0 1.466-.445v2.173q-.393.183-.812.262-.393.08-1.02.08z"
                />
            </defs>
            <clipPath id="b">
                <use xlinkHref="#a" />
            </clipPath>
            <g clipPath="url(#b)">
                <path d="M520 939.5 959.4 500H639.9L360.3 779.7z" fill="#39cefd" />
            </g>
            <defs>
                <path
                    id="c"
                    d="M959.4 500 679.8 779.7l279.6 279.7H639.9L360.2 779.7 639.9 500h319.5zM639.9 20.7 120.6 540l159.8 159.8 679-679.1H639.9z"
                />
            </defs>
            <clipPath id="d">
                <use xlinkHref="#c" />
            </clipPath>
            <path
                clipPath="url(#d)"
                d="M120.6 540 639.9 20.7h319.5l-679 679.1z"
                fill="#39cefd"
            />
            <defs>
                <path
                    id="e"
                    d="M959.4 500 679.8 779.7l279.6 279.7H639.9L360.2 779.7 639.9 500h319.5zM639.9 20.7 120.6 540l159.8 159.8 679-679.1H639.9z"
                />
            </defs>
            <clipPath id="f">
                <use xlinkHref="#e" />
            </clipPath>
            <path
                clipPath="url(#f)"
                d="M639.9 1059.3h319.5L679.8 779.7 520 939.5z"
                fill="#03569b"
            />
            <defs>
                <path
                    id="g"
                    d="M959.4 500 679.8 779.7l279.6 279.7H639.9L360.2 779.7 639.9 500h319.5zM639.9 20.7 120.6 540l159.8 159.8 679-679.1H639.9z"
                />
            </defs>
            <clipPath id="h">
                <use xlinkHref="#g" />
            </clipPath>
            <linearGradient
                id="i"
                gradientUnits="userSpaceOnUse"
                x1={566.635}
                y1={970.339}
                x2={685.65}
                y2={851.324}
            >
                <stop offset={0} stopColor="#1a237e" stopOpacity={0.4} />
                <stop offset={1} stopColor="#1a237e" stopOpacity={0} />
            </linearGradient>
            <path
                clipPath="url(#h)"
                d="M679.8 779.7 520 939.5l237-82.1z"
                fill="url(#i)"
            />
            <defs>
                <path
                    id="j"
                    d="M959.4 500 679.8 779.7l279.6 279.7H639.9L360.2 779.7 639.9 500h319.5zM639.9 20.7 120.6 540l159.8 159.8 679-679.1H639.9z"
                />
            </defs>
            <clipPath id="k">
                <use xlinkHref="#j" />
            </clipPath>
            <g clipPath="url(#k)">
                <path
                    d="M360.282 779.645 520.086 619.84 679.9 779.645 520.086 939.45z"
                    fill="#16b9fd"
                />
            </g>
            <radialGradient
                id="l"
                cx={7824.659}
                cy={-2855.979}
                r={5082.889}
                gradientTransform="matrix(.25 0 0 -.25 -1812 -622.5)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0} stopColor="#fff" stopOpacity={0.1} />
                <stop offset={1} stopColor="#fff" stopOpacity={0} />
            </radialGradient>
            <path
                d="M959.4 500 679.8 779.7l279.6 279.7H639.9L360.2 779.7 639.9 500h319.5zM639.9 20.7 120.6 540l159.8 159.8 679-679.1H639.9z"
                fill="url(#l)"
            />
        </g>
        <g fill="gray">
            <path d="M40.62 19.774h10.84v2.12h-8.64v6.414h7.8v2.094h-7.8v8.115h-2.2V19.774zm13.463 0h2.225v18.744h-2.225V19.774zm10.365 19.163q-2.408 0-3.7-1.414Q59.5 36.1 59.5 33.57v-8.403h2.225v8.063q0 1.9.864 2.8.864.9 2.33.9 1.126 0 2-.602.9-.602 1.36-1.57.497-.97.497-2.042v-7.54H71v13.35h-2.12V36.58h-.105q-.55.995-1.754 1.675t-2.566.68z" />
            <use xlinkHref="#m" />
            <use xlinkHref="#m" x={9.436} />
            <path d="M98.8 38.937q-1.9 0-3.43-.916-1.518-.916-2.382-2.513-.838-1.623-.838-3.64 0-1.937.785-3.56.812-1.623 2.278-2.592 1.466-.97 3.403-.97 1.963 0 3.403.9 1.44.864 2.2 2.408.785 1.545.785 3.534 0 .393-.08.68h-10.55q.08 1.518.733 2.566.654 1.047 1.65 1.57 1.02.524 2.12.524 2.566 0 3.953-2.408l1.885.916q-.864 1.623-2.356 2.566-1.466.942-3.56.942zm3.848-8.508q-.052-.838-.47-1.675-.42-.838-1.335-1.414-.9-.576-2.25-.576-1.57 0-2.67 1.02-1.073.995-1.414 2.644h8.142zm4.934-5.262h2.12v2.147h.105q.393-1.1 1.518-1.806 1.126-.733 2.356-.733.916 0 1.57.262v2.408q-.838-.42-1.885-.42-.97 0-1.78.55-.812.55-1.3 1.492-.47.916-.47 2v7.46h-2.225v-13.35z" />
        </g>
    </svg>
)

export default LogoFlutter
