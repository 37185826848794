import * as React from "react"

const LogoNode = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={161}
    height={60}
    preserveAspectRatio="xMidYMid"
    {...props}
  >
    <path
      d="M140.304 24.194c-.345 0-.68.071-.977.239l-9.391 5.423a1.979 1.979 0 0 0-.978 1.715V42.4c0 .703.37 1.363.978 1.714l2.452 1.414c1.192.587 1.631.578 2.174.578 1.764 0 2.771-1.07 2.771-2.931V32.492c0-.151-.13-.26-.279-.26h-1.176c-.151 0-.28.111-.28.26v10.687c0 .825-.859 1.655-2.253.957l-2.55-1.495c-.09-.05-.159-.139-.159-.24V31.575c0-.102.069-.207.16-.259l9.372-5.404a.276.276 0 0 1 .279 0l9.371 5.404c.09.054.16.154.16.26v10.827a.3.3 0 0 1-.14.259l-9.391 5.404a.289.289 0 0 1-.28 0l-2.412-1.436a.25.25 0 0 0-.24 0c-.665.378-.79.422-1.415.638-.153.054-.388.137.08.4l3.15 1.856c.3.174.635.26.978.26.348 0 .677-.084.977-.26l9.391-5.404a1.983 1.983 0 0 0 .977-1.714V31.577a1.98 1.98 0 0 0-.977-1.715l-9.391-5.423a1.958 1.958 0 0 0-.977-.24zm2.513 7.737c-2.675 0-4.267 1.138-4.267 3.03 0 2.051 1.58 2.618 4.147 2.872 3.07.3 3.31.751 3.31 1.356 0 1.048-.836 1.495-2.811 1.495-2.482 0-3.028-.62-3.21-1.857-.022-.132-.124-.239-.26-.239h-1.22a.285.285 0 0 0-.278.279c0 1.58.86 3.45 4.965 3.45 2.972 0 4.686-1.164 4.686-3.21 0-2.024-1.385-2.57-4.267-2.952-2.91-.388-3.19-.574-3.19-1.256 0-.564.235-1.317 2.392-1.317 1.927 0 2.64.416 2.931 1.715a.275.275 0 0 0 .26.222h1.219c.075 0 .147-.046.2-.1.05-.057.086-.122.08-.2-.19-2.237-1.681-3.29-4.687-3.29z"
      fill="#83cd29"
    />
    <path
      d="M85.092 10a.604.604 0 0 0-.3.08.604.604 0 0 0-.298.518v15.254c0 .15-.07.284-.2.36a.416.416 0 0 1-.419 0l-2.492-1.436a1.194 1.194 0 0 0-1.196 0l-9.95 5.742a1.225 1.225 0 0 0-.599 1.057V43.06a1.194 1.194 0 0 0 .599 1.036l9.95 5.743a1.192 1.192 0 0 0 1.196 0l9.95-5.743a1.194 1.194 0 0 0 .598-1.036V14.427c0-.436-.238-.845-.618-1.057l-5.922-3.31a.587.587 0 0 0-.3-.06zM21.086 24.337c-.19.007-.368.04-.538.14l-9.95 5.742A1.192 1.192 0 0 0 10 31.255l.02 15.414c0 .215.111.414.3.518a.58.58 0 0 0 .598 0l5.902-3.39c.374-.221.618-.608.618-1.036v-7.198c0-.43.225-.824.598-1.037l2.513-1.455a1.178 1.178 0 0 1 .598-.16 1.175 1.175 0 0 1 .598.16l2.511 1.454c.373.214.598.608.598 1.037v7.198c0 .428.25.817.618 1.036l5.902 3.39a.6.6 0 0 0 .897-.518l.02-15.413a1.186 1.186 0 0 0-.597-1.037l-9.95-5.742a1.118 1.118 0 0 0-.538-.14h-.12zm89.408.14a1.189 1.189 0 0 0-.598.159l-9.95 5.743a1.194 1.194 0 0 0-.598 1.036V42.9c0 .432.243.823.618 1.037l9.87 5.625c.364.208.809.224 1.176.02l5.982-3.33a.588.588 0 0 0 .02-1.036l-10.01-5.743a.644.644 0 0 1-.32-.539v-3.591c0-.215.134-.411.32-.518l3.11-1.795a.598.598 0 0 1 .599 0l3.111 1.795a.596.596 0 0 1 .3.518v2.831a.596.596 0 0 0 .298.518.594.594 0 0 0 .6 0l5.96-3.468a1.2 1.2 0 0 0 .6-1.037v-2.771a1.197 1.197 0 0 0-.599-1.037l-9.89-5.743a1.186 1.186 0 0 0-.598-.16zm-29.75 8.574a.335.335 0 0 1 .16.04l3.41 1.973a.31.31 0 0 1 .16.26v3.947a.307.307 0 0 1-.16.26l-3.41 1.973a.303.303 0 0 1-.299 0l-3.41-1.974a.309.309 0 0 1-.16-.259v-3.948a.31.31 0 0 1 .16-.26l3.41-1.973a.262.262 0 0 1 .14-.04z"
      fill="#404137"
    />
    <path
      d="M50.975 24.416a1.194 1.194 0 0 0-.598.16l-9.95 5.722a1.225 1.225 0 0 0-.598 1.057V42.84a1.192 1.192 0 0 0 .598 1.037l9.95 5.742a1.192 1.192 0 0 0 1.196 0l9.95-5.742a1.194 1.194 0 0 0 .598-1.037V31.355c0-.43-.226-.843-.598-1.057l-9.95-5.723a1.183 1.183 0 0 0-.599-.16zm59.5 10.25c-.04 0-.084 0-.12.019l-1.914 1.116a.233.233 0 0 0-.12.2v2.193c0 .083.048.158.12.2l1.914 1.096a.208.208 0 0 0 .22 0l1.913-1.096c.071-.041.12-.117.12-.2v-2.193a.235.235 0 0 0-.12-.2l-1.914-1.117c-.035-.02-.06-.02-.1-.02z"
      fill="#83cd29"
    />
  </svg>
)

export default LogoNode

