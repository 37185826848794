import React, { useContext } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import ScrollableAnchor from 'react-scrollable-anchor';
import dayjs from 'dayjs';
import Carousel from 'react-multi-carousel';
import LocalizedTag from '../i18n/LocalizedTag';
import forCompanies from '../../assets/images/workshop-groups.jpg';
import forIndividuals from '../../assets/images/workshop-individuals.jpg';
import benefity from '../../assets/images/benefity_logo.jpg';
import transfer from '../../assets/images/bankovnim_prevodem_logo.png';
import TopicCard from './TopicCard';
import TrainingCard from './TrainingCard';
import 'react-multi-carousel/lib/styles.css';
import LanguageContext from '../i18n/LanguageContext';
import { WORKSHOP_STATUS } from '../utils/constants';
import pdfForCompanies from '../../static/download/Nabidka_skoleni_firmy.pdf';

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const detailsQuery = graphql`
  query MyQuery {
    allWorkshops(sort: { fields: date, order: ASC }, limit: 1000) {
      nodes {
        friendlyUrl
        eventLink
        date
        key
        name
        place
        price
        status
        img {
          childImageSharp {
            gatsbyImageData(width: 170, height: 140, layout: FIXED)
          }
        }
        dateFreeText
      }
    }
    allTopics {
      nodes {
        topicDescription
        topicName
        key
        topicUrl
        topicOrder
      }
    }
    allTrainings(sort: { fields: key, order: ASC }, limit: 1000) {
      nodes {
        friendlyUrl
        eventLink
        key
        name
        status
        img {
          childImageSharp {
            gatsbyImageData(width: 170, height: 140, layout: FIXED)
          }
        }
      }
    }
  }
`;

const RecentWorkshops = () => {
  const currentLanguage = useContext(LanguageContext);
  const isCzech = currentLanguage.locale === 'cs';

  return (
    <StaticQuery
      query={detailsQuery}
      render={({ allTrainings, allWorkshops, allTopics }) => (
        <ScrollableAnchor id="workshop">
          <section className="Section Section--themeLight Section--workshops">
            <div className="Section-main">
              <LocalizedTag id="workshops" type="h2" className="Workshops-titles" />
              <div className="Section-titleBox">
                <LocalizedTag id="workshopsTitle" type="h3" className="Section-title" />
              </div>
              <div className="Workshops">
                <div className="Workshops-wrapper">
                  <div className="Workshops-forWho">
                    <div className="Workshops-subject">
                      <a href={pdfForCompanies} download>
                        <img
                          src={forCompanies}
                          alt="for companies"
                          height="250"
                          width="420"
                          className="Workshops-image"
                        />
                      </a>
                      <LocalizedTag id="workshopsCompany" type="h3" className="Workshops-name" />
                      <LocalizedTag
                        id="workshopsCompanyText"
                        type="p"
                        className="Workshops-description"
                      />
                    </div>
                    <div className="Workshops-subject">
                      <img
                        src={forIndividuals}
                        alt="for individuals"
                        height="250"
                        width="420"
                        className="Workshops-image"
                      />
                      <LocalizedTag
                        id="workshopsIndividuals"
                        type="h3"
                        className="Workshops-name"
                      />
                      <LocalizedTag
                        id="workshopsIndividualsText"
                        type="p"
                        className="Workshops-description"
                      />
                    </div>
                  </div>
                </div>

                {isCzech && (<>


                    {allTopics.nodes
                      .filter((topic) =>
                        allWorkshops.nodes.find(
                          (w) =>
                            w.name === topic.topicName &&
                            dayjs(w.date).isAfter(new Date()) &&
                            w.status === WORKSHOP_STATUS.active
                        )
                      )
                      .sort((a, b) => a.topicOrder - b.topicOrder)
                      .map((t) => (
                        <TopicCard allWorkshops={allWorkshops.nodes} topic={t} key={t.key} />
                      ))}
                    <div className="Workshops-separator">
                      <div className="Workshops-line" />
                      <LocalizedTag id="trainingsTopics" type="h2" className="Workshops-titles" />
                      <div className="Workshops-line" />
                    </div>

                    {allTopics.nodes
                      .filter((topic) =>
                        allTrainings.nodes.find(
                          (w) => w.name === topic.topicName && w.status === WORKSHOP_STATUS.active
                        )
                      )
                      .sort((a, b) => a.topicOrder - b.topicOrder)
                      .map((t) => (
                        <TrainingCard allTrainings={allTrainings.nodes} topic={t} key={t.key} />
                      ))}
                  </>
                )}
                <div className="Workshops-forWho">
                  <div className="Workshops-videoBox">
                    <LocalizedTag
                      id="workshopsFreeVideo"
                      type="h3"
                      className="Workshops-name Workshops-margin"
                    />
                    <div className="Workshops-video">
                      <iframe
                        title="SQN-promo-video"
                        className="Workshops-videoIframe"
                        src="https://www.youtube.com/embed/wMTf_GLpaTI"
                        frameBorder="0"
                      />
                    </div>
                  </div>
                </div>
                {isCzech && (
                  <>
                    <div className="Workshops-lastSeparator">
                      <div className="Workshops-line" />
                      <LocalizedTag
                        id="workshopsPaymentMethods"
                        type="h2"
                        className="Workshops-titles"
                      />
                      <div className="Workshops-line" />
                    </div>

                    <Carousel responsive={responsive}>
                      <img
                        src={benefity}
                        alt="for individuals"
                        width="180"
                        className="Workshops-imagePayments"
                      />
                      <img
                        src={transfer}
                        alt="for individuals"
                        width="180"
                        className="Workshops-imagePayments"
                      />
                    </Carousel>
                  </>
                )}
              </div>
            </div>
          </section>
        </ScrollableAnchor>
      )}
    />
  );
};

export default RecentWorkshops;
